import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faBars, faCircle, faAngleDown, faBarsStaggered, faArrowRightFromBracket, faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { BWLogo, CyberbloxTextLogo } from "../Assets";
import "./CSS/Navbar.css";

const Navbar = () => {
  const [isBarVisible, setBarVisibility] = useState(true);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false); //Desktop Dropdown Menu
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [pfp, setPFP] = useState(BWLogo);
  const [theme, setTheme] = useState("theme-light");

  const navigate = useNavigate();

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
      document.documentElement.className = savedTheme;
    }
  }, []);

  useEffect(() => {
    const checkLoggedInStatus = async () => {
      const isLoggedInCookie = document.cookie.includes("token");
      setIsLoggedIn(isLoggedInCookie);
      if (isLoggedInCookie) {
        try {
          const response = await fetch("/api/fetch-dashboard-data");
          if (response.ok) {
            const data = await response.json();
            await setUsername(data.Username ? data.Username : "Failed Fetch");
            await setPFP(data.AvatarURL ? data.AvatarURL : BWLogo);
            setLoading(false);
          } else {
            console.error("Failed to fetch username");
          }
        } catch (error) {
          console.error("Error fetching username:", error);
        }
      }
    };

    checkLoggedInStatus();

    const dismissedBarCookie = document.cookie.split(";").find((cookie) => cookie.trim().startsWith("dismissedBar="));
    if (dismissedBarCookie && dismissedBarCookie.trim() === "dismissedBar=true") {
      setBarVisibility(false); // Hide the notification bar
    }
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const dismissBar = () => {
    setBarVisibility(false);

    const expirationTime = new Date();
    expirationTime.setTime(expirationTime.getTime() + 12 * 60 * 60 * 1000);

    const expires = expirationTime.toUTCString();

    document.cookie = `dismissedBar=true; expires=${expires}; path=/`;
  };

  const toggleTheme = () => {
    const newTheme = theme === "theme-light" ? "theme-dark" : "theme-light";
    setTheme(newTheme);
    document.documentElement.className = newTheme; // Set class on <html> element
    localStorage.setItem("theme", newTheme);
  };

  const handleNavigation = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  return (
    <>
      <header>
        <div className="logo">
          <a className="logo-link" href="/" onClick={(event) => handleNavigation(event, "/")}>
            <img src={CyberbloxTextLogo} alt="Cyberblox" className="logo-image" />
          </a>
        </div>
        <div className="header-menu" onClick={toggleDropdown}>
          <FontAwesomeIcon icon={isDropdownOpen ? faTimesCircle : faBars} />
        </div>
        <ul className="navbar">
          <li>
            <a href="/" onClick={(event) => handleNavigation(event, "/")} title="Home">
              Home
            </a>
          </li>
          <li>
            <a href="https://docs.cyberblox.org" title="Docs" target="_blank" rel="noreferrer">
              Docs
            </a>
          </li>
          {/* <li>
            <a href="/premium" onClick={(event) => handleNavigation(event, "/premium")} title="Premium">
              Premium
            </a>
          </li> */}
          <li>
            <a href="/support" onClick={(event) => handleNavigation(event, "/support")} title="Contact Support">
              Support
            </a>
          </li>
          <a className="invite-button" href="https://invite.cyberblox.org/" title="Invite Cyberrblox" target="_blank" rel="noreferrer">
            Invite Cyberblox
          </a>
        </ul>
        {isLoggedIn && (
          <div className="header-buttons">
            <div className="theme-selection" onClick={toggleTheme}>
              <FontAwesomeIcon icon={theme === "theme-light" ? faMoon : faSun} />
            </div>
            <div className="menu" onClick={loading ? null : toggleMenu}>
              <div className={`profile-menu ${loading ? " loading" : ""}`}>
                {loading ? (
                  <div className="loading-dots">
                    <FontAwesomeIcon icon={faCircle} className="dot-1" />
                    <FontAwesomeIcon icon={faCircle} className="dot-2" />
                    <FontAwesomeIcon icon={faCircle} className="dot-3" />
                  </div>
                ) : (
                  <>
                    <img className="pfp-image" src={pfp} alt="PFP" />
                    <span className="username">{username}</span>
                    <FontAwesomeIcon icon={faAngleDown} className={`menu-icon ${menuVisible ? "open" : ""}`} />
                  </>
                )}
              </div>
              {menuVisible && (
                <div className="menu-content">
                  {/* <a href="/dashboard/servers" onClick={(event) => handleNavigation(event, "/dashboard/servers")} title="View Dashboard">
                    {" "}
                    <FontAwesomeIcon icon={faBarsStaggered} /> Dashboard
                  </a> */}
                  {/* <a href="/settings">
                    {" "}
                    <FontAwesomeIcon icon={faGear} /> Settings
                  </a> */}
                  <a href="/api/logout" title="Logout">
                    {" "}
                    <FontAwesomeIcon icon={faArrowRightFromBracket} /> Logout
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
        {!isLoggedIn && (
          <div className="header-buttons">
            <div className="theme-selection" onClick={toggleTheme}>
              <FontAwesomeIcon icon={theme === "theme-light" ? faMoon : faSun} />
            </div>
            {/* <a href={`/api/login?originalUrl=${window.location.href}`} className="sign-in-button">
              Login Using Discord
            </a> */}
          </div>
        )}
        <div className={`dropdown-menu ${isDropdownOpen ? "open" : "close"}`}>
          <ul>
            <li>
              <a href="/" onClick={(event) => handleNavigation(event, "/")} title="Home">
                Home
              </a>
            </li>
            <li>
              <a href="https://docs.cyberblox.org/" target="_blank" rel="noreferrer">
                Docs
              </a>
            </li>
            {/* <li>
              <a href="/premium" onClick={(event) => handleNavigation(event, "/premium")}>
                Premium
              </a>
            </li> */}
            <li>
              <a href="/support" onClick={(event) => handleNavigation(event, "/support")}>
                Support
              </a>
            </li>
          </ul>
          {isLoggedIn && (
            <a href={`/dashboard/servers`} className="dashboard-button">
              Dashboard
            </a>
          )}
          {/* {!isLoggedIn && (
            <a href={`/api/login?originalUrl=${window.location.href}`} className="sign-in-button">
              Login Using Discord
            </a>
          )} */}
        </div>
      </header>

      {/* {isBarVisible && (
        <div className="notification-bar">
          <p>
            🎉New Update! Join our support server! 🔨{" "}
            <a href="https://discord.cyberblox.org/" target="_blank" rel="noreferrer">
              discord.cyberblox.org
            </a>
          </p>
          <button className="dismiss-button" onClick={dismissBar}>
            <i>
              <FontAwesomeIcon icon={faTimesCircle} />
            </i>
          </button>
        </div>
      )} */}
    </>
  );
};

export default Navbar;
